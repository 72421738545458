/** ========================================================= * NextJS Material Dashboard 2 PRO - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/nextjs-material-dashboard-pro * Copyright 2022 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

// react
import { useEffect } from 'react';

// next
import Router from 'next/router';

export default function Custom404() {
  useEffect(() => {
    Router.push('/');
  });

  return <div />;
}
